
import { defineComponent } from 'vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import useValidate from '@vuelidate/core';
import { RequestTenantReference } from '@/views/new-design/validations/RequestTenantReferences';
import States from '@/components/forms/components/StateSelect.vue';
import Countries from '@/components/forms/components/CountriesSelect.vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '@/store/enums/SystemEnums';
import { Actions as StoreActions } from '@/store/enums/StoreEnums';
import {
  Actions as RefActions,
  Getters as RefGetters,
} from '@/store/enums/ReferenceRequestEnums';
import toasts from '@/utils/toasts';
import _ from 'lodash';
import maskUtil from '@/utils/mask';
import FormField from '@/components/forms/FormField.vue';
import store from '@/store';
import AddressLookup from '@/components/AddressLookup.vue';
import text from '@/utils/text';

export default defineComponent({
  components: { Navigator, Countries, States, FormField, AddressLookup },
  data: () => {
    return {
      v$: useValidate(),
      form: {
        address_line: null,
        agency_name: null,
        property_manager_email: null,
        city: null,
        country: 'AU' as any,
        first_name: null,
        last_name: null,
        date_of_birth: null,
        post_code: null,
        property_manager_mobile: null,
        property_manager_name: null,
        request_from: 'agent',
        state: null as any,
      },
      states: [],
      loading: false,
      isManual: false,
    };
  },
  validations() {
    return RequestTenantReference;
  },

  mounted() {
    // show page loading
    setTimeout(() => {
      store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 500);
  },
  computed: {
    ...mapGetters({
      getStates: Getters.GET_LIST_STATES,
      requestErrors: RefGetters.GET_ACTION_ERROR,
    }),
    maskPostCode() {
      return maskUtil.postCode.aus;
    },
    maskMobile() {
      return maskUtil.mobile.aus;
    },
    addressValidationErrors(){
      return [
        ...this.v$.form.address_line.$errors,
        ...this.v$.form.post_code.$errors,
        ...this.v$.form.city.$errors,
        ...this.v$.form.state.$errors,
        ...this.v$.form.country.$errors
      ];
    }
  },
  methods: {
    ...mapActions({
      listStates: Actions.LIST_STATES,
      requestTenantReference: RefActions.SEND_REQUEST_TENANT_REFERENCE,
    }),
    setPlace(place) {
      this.form.state = place.state;
      this.form.city = place.city;
      this.form.post_code = place.post_code;
      this.form.country = place.country;
      this.form.address_line = place.address;
    },
    resetForm() {
      this.v$.$reset();

      _.forEach(this.form, (value, key) => {
        this.form[key] = null as any;
        if (key == 'country') this.form[key] = 'AU' as any;
      });
    },
    submit() {
      this.v$.$validate(); // checks all inputs

      if (this.v$.$error) {
        return false;
      }

      if (this.loading) return;

      this.loading = true;

      this.form.request_from = 'agent';

      this.requestTenantReference(this.form)
        .then(() => {
          this.resetForm();
          toasts.success('Tenant Reference Request Successfully Sent');
        })
        .catch(() => {
          const { errors, message } = this.requestErrors;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCountrySelect(value) {
      this.handleCountryStatesSelect(value);
      this.form.country = value;
    },
    handleStateSelect(value) {
      this.form.state = value;
    },
    handleDob(value) {
      this.form.date_of_birth = value;
    },
    async handleCountryStatesSelect(value) {
      if (value) {
        await this.listStates(value).then(async () => {
          this.states = Object.values(await this.getStates);
        });
      }
    },
    isNumber(e){
      return text.isNumber(e);
    }
  },
  setup() {
    // show page loading
    store.dispatch(StoreActions.ADD_BODY_CLASSNAME, 'page-loading');
  },
});
